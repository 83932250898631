const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_GUILD_BASE_URL = `${API_BASE_URL}/guilds/{guildId}`;

exports.API_METHOD = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

exports.FRENBOT = {
  API: {
    PLUGIN_URL: `${API_GUILD_BASE_URL}/plugins`,
  },
  DEFAULT_COLORS: [
    "#ffffff", // White
    "#2ecc71", // Light green
    "#3498db", // Light blue
    "#9b59b6", // Light purple
    "#e91e63", // Light pink
    "#f1c40f", // Light yellow
    "#000000", // Black
    "#11806a", // Dark green
    "#206694", // Dark blue
    "#71368a", // Dark purple
    "#ad1457", // Dark pink
    "#e67e22", // Dark yellow
  ],
};

exports.API = {
  GUILD: {
    PAGE: {
      PLUGIN_GALLERY: {
        URL: `${API_GUILD_BASE_URL}/pages/plugin-gallery`,
        METHOD: exports.API_METHOD.GET,
      },
      WELCOME: {
        URL: `${API_GUILD_BASE_URL}/pages/welcome`,
        METHOD: exports.API_METHOD.GET,
      },
      LEVEL: {
        URL: `${API_GUILD_BASE_URL}/pages/level`,
        METHOD: exports.API_METHOD.GET,
      },
      MODERATOR: {
        URL: `${API_GUILD_BASE_URL}/pages/moderator`,
        METHOD: exports.API_METHOD.GET,
      },
      GIVEAWAY: {
        URL: `${API_GUILD_BASE_URL}/pages/giveaway`,
        METHOD: exports.API_METHOD.GET,
      },
      REACTION_ROLES: {
        URL: `${API_GUILD_BASE_URL}/pages/reaction-roles`,
        METHOD: exports.API_METHOD.GET,
      },
      COLLECTION_SALES: {
        URL: `${API_GUILD_BASE_URL}/pages/collection-sales`,
        METHOD: exports.API_METHOD.GET,
      },
      NEWS: {
        URL: `${API_GUILD_BASE_URL}/pages/news`,
        METHOD: exports.API_METHOD.GET,
      },
      RARITY: {
        URL: `${API_GUILD_BASE_URL}/pages/rarity`,
        METHOD: exports.API_METHOD.GET,
      },
      VERIFICATION: {
        URL: `${API_GUILD_BASE_URL}/pages/verification`,
        METHOD: exports.API_METHOD.GET,
      },
    },

    TOOGLE_PLUGIN: {
      URL: `${API_GUILD_BASE_URL}/plugins/toogle`,
      METHOD: exports.API_METHOD.PATCH,
    },

    WELCOME_PLUGIN: {
      EDIT: {
        URL: `${API_GUILD_BASE_URL}/plugins/welcome`,
        METHOD: exports.API_METHOD.PATCH,
      },
    },

    LEVEL_PLUGIN: {
      EDIT: {
        URL: `${API_GUILD_BASE_URL}/plugins/level`,
        METHOD: exports.API_METHOD.PATCH,
      },
    },

    MODERATOR_PLUGIN: {
      EDIT: {
        URL: `${API_GUILD_BASE_URL}/plugins/moderator`,
        METHOD: exports.API_METHOD.PATCH,
      },
    },

    GIVEAWAY_PLUGIN: {
      NEW: {
        URL: `${API_GUILD_BASE_URL}/plugins/giveaway`,
        METHOD: exports.API_METHOD.POST,
      },
    },

    REACTION_ROLES_PLUGIN: {
      NEW: {
        URL: `${API_GUILD_BASE_URL}/plugins/reaction-roles`,
        METHOD: exports.API_METHOD.POST,
      },
    },

    COLLECTION_SALES_PLUGIN: {
      EDIT: {
        URL: `${API_GUILD_BASE_URL}/plugins/collection-sales`,
        METHOD: exports.API_METHOD.PATCH,
      },
    },

    NEWS_PLUGIN: {
      EDIT: {
        URL: `${API_GUILD_BASE_URL}/plugins/news`,
        METHOD: exports.API_METHOD.PATCH,
      },
    },

    RARITY_PLUGIN: {
      EDIT: {
        URL: `${API_GUILD_BASE_URL}/plugins/rarity`,
        METHOD: exports.API_METHOD.PATCH,
      },
    },

    VERIFICATION_PLUGIN: {
      EDIT: {
        URL: `${API_GUILD_BASE_URL}/plugins/verification`,
        METHOD: exports.API_METHOD.PATCH,
      },
    },
  },

  USER: {
    ME: {
      URL: `${API_BASE_URL}/users/@me`,
      METHOD: exports.API_METHOD.GET,
    },
    MANAGED_GUILDS: {
      URL: `${API_BASE_URL}/users/@me/managed-guilds`,
      METHOD: exports.API_METHOD.GET,
    },
    VERIFY_WALLET: {
      URL: `${API_BASE_URL}/users/@me/wallet-verify`,
      METHOD: exports.API_METHOD.POST,
    },
    CAN_SEE: {
      URL: `${API_BASE_URL}/users/@me/can-see/{page}`,
      METHOD: exports.API_METHOD.GET,
    },
  },

  AUTH: {
    DISCORD: {
      URL: `${API_BASE_URL}/oauth2/discord-callback?code={code}`,
      METHOD: exports.API_METHOD.GET,
    },
  },

  PAGE: {
    NEWS: {
      PUBLISH: {
        URL: `${API_BASE_URL}/news/publish`,
        METHOD: exports.API_METHOD.POST,
      },
    },
  },
};

exports.AUTH = {
  LINK: {
    AUTH: `https://discord.com/oauth2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_URL}/discord_callback&response_type=code&scope=identify%20guilds`,
    BOT_INVITE: `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_URL}/guild_oauth&permissions=8&scope=bot%20applications.commands&guild_id={guildId}`,
  },

  POP_UP_CONFIG:
    "menubar=yes,location=yes,resizable=no,scrollbars=yes,status=yes,width=500,height=800",

  TOKEN_TYPE: "Bearer",

  LOCAL_STORAGE: {
    TOKEN: "accessToken",
    USER_DATA: "userData",
  },
};

exports.WELCOME_PLUGIN = {
  API: {
    EDIT: {
      URL: `${exports.FRENBOT.API.PLUGIN_URL}/welcome/`,
      METHOD: exports.API_METHOD.PATCH,
    },
  },
};
