// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const guildSlice = createSlice({
  name: "guild",
  initialState: {},
  reducers: {
    handleGuild: (state, action) => {
      state.guild = action.payload;
    },
  },
});

export const { handleGuild } = guildSlice.actions;

export default guildSlice.reducer;
