// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

import { AUTH } from "@src/constants";

const accessTokenKey = AUTH.LOCAL_STORAGE.TOKEN;
const userDataKey = AUTH.LOCAL_STORAGE.USER_DATA;

export const authSlice = createSlice({
  name: "authentication",
  initialState: {},
  reducers: {
    handleLogin: (state, action) => {
      state[userDataKey] = action.payload[userDataKey];
      state[accessTokenKey] = action.payload[accessTokenKey];
    },
    handleLogout: (state) => {
      state[userDataKey] = null;
      state[accessTokenKey] = null;

      localStorage.removeItem(accessTokenKey);
    },
  },
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
