// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import guild from "./guild";

const rootReducer = {
  auth,
  guild,
  navbar,
  layout,
};

export default rootReducer;
